import React from 'react';
import { useNavigate } from 'react-router';
import { constants } from '../../URL';
import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    IconButton,
    Button,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    ChakraProvider
} from '@chakra-ui/react';
import {
    MdPhone,
    MdEmail,
    MdLocationOn,
    MdFacebook,
    MdOutlineEmail,
} from 'react-icons/md';
import { BsGithub, BsDiscord, BsPerson } from 'react-icons/bs';

const ContactUs = (props) => {

    const navigate = useNavigate();

    return (
        <>
            <div class="col-lg-12 p-4 bg-white rounded shadow-sm">
                <h4 class="mb-4 profile-title">Contact Us</h4>
                <div class="help_support">
                    <div className="address_contact">
                        <h5>Address :</h5>
                        <p>13165 Beverly Park Rd , Lynnwood WA <br /> 98087, United States</p>
                        <p>Call Us @ <br /> <a href={"tel:"+constants.phone2}>{constants.phone2}</a></p>
                        <p>Call Us @ <br /> <a href={"tel:"+constants.phone}>{constants.phone}</a></p>
                        <p>Whatsapp @ <br /> <a href={`https://api.whatsapp.com/send?text=Hello!&phone=${constants.phone}`}>{constants.phone}</a></p>
                        <p>Email @ <br /> <a href={`mailto:${constants.email}`}>{constants.email}</a></p>
                    </div>
                
                </div>
            </div>
        </>
    )

}

export default ContactUs;