import React, { useContext, useEffect, useState, useRef } from 'react';
import contextData from '../../context/MainContext';
import { constants } from '../../URL';
import { useMediaQuery } from '@chakra-ui/react';
// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';


const TopHead = ({ setSearchTerm, onFocus, onBlur, focused, searchedProduct, wrapperRef }) => {

    const data = useContext(contextData);
    const navigate = useNavigate();


    return (
        <>
            <div class="top-header-group">
                <div class="top-header">
                    <div class="res_main_logo">
                        <Link to="/"><img src="/images/small-logo.png" alt="" /></Link>
                    </div>
                    <div class="main_logo" id="logo">
                        <Link to="/"><img src="/images/full-logo.png" alt="" /></Link>
                        <Link to="/"><img class="logo-inverse" src="/images/small-logo.png" alt="" /></Link>
                    </div>
                    <div class="search120">
                        <div class="ui search">
                            <div class="ui left icon input swdh10" ref={wrapperRef}>
                                <input class="prompt srch10" onFocus={onFocus} onChange={e => setSearchTerm(e.target.value)} type="text" placeholder="Search for products.." />
                                <i class='uil uil-search-alt icon icon1'></i>
                                {focused ? (
                                    <div class="search-items-layout" aria-labelledby="dropdownMenuButton">
                                        {searchedProduct.slice(0, 10).map((item, i) => {
                                            return (
                                                <Link onClick={onBlur} class="dropdown-item" to={"/" + (item.product_name + " ").replace(/\s/g, "-").toLowerCase() + "/" + item.id}>{item.product_name}</Link>
                                            )
                                        })}
                                    </div>
                                ) : (null)}
                            </div>
                        </div>
                    </div>
                    <div class="header_right">
                        <ul>
                            <li>
                                <a class="offer-link"><i class="uil uil-phone-alt"></i>{constants.phone}</a>
                            </li>
                            {/*  <li>
                                <a href="#" onClick={() => navigate("/offers")} class="offer-link"><i class="uil uil-gift"></i>Offers</a>
                            </li> */}
                            <li>
                                <a onClick={() => navigate("/faq")} href="#" class="offer-link"><i class="uil uil-question-circle"></i>Help</a>
                            </li>

                            <li class="ui dropdown" tabIndex="0">
                                <Dropdown overlay={
                                    <div class="menu dropdown_account">
                                        <Menu>
                                            {data.auth.isUserLogin ?
                                                <>
                                                    <Menu.Item>
                                                        <a href="javascript:void(0)" onClick={() => navigate("/orders")} class="item channel_item"><i class="uil uil-box icon__1"></i>My Orders</a>
                                                    </Menu.Item>
                                                  
                                                    {/* <Menu.Item>
                                                        <a href="javascript:void(0)" onClick={() => navigate("/offers")} class="item channel_item"><i class="uil uil-gift icon__1"></i>Offers</a>
                                                    </Menu.Item> */}
                                                    <Menu.Item>
                                                        <a href="javascript:void(0)" onClick={() => navigate("/contact")} class="item channel_item"><i class="uil uil-info-circle icon__1"></i>Faq</a>
                                                    </Menu.Item>
                                                    {/* <Menu.Item>
                                                        <a href="javascript:void(0)" onClick={() => data.logOut()} class="item channel_item"><i class="uil uil-lock-alt icon__1"></i>Logout</a>
                                                    </Menu.Item> */}
                                                </> : null
                                            }
                                        </Menu>
                                    </div>
                                }>
                                    <a href="#" class="opts_account ant-dropdown-link">
                                        <img src="/images/avatar/img-5.jpg" alt="" />
                                        <span class="user__name">{data.auth.isUserLogin ? "Hey" : "Guest"}</span>
                                        <i class="uil uil-angle-down"></i>
                                    </a>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )

}

export default TopHead;


