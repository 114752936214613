import React from 'react';

import { useLocation } from 'react-router-dom'

import Seo from '../Seo'

const Retun = ({ conditionTitel, conditionData }) => {
    const location = useLocation();

    return (
        <>

            <Seo
                title={conditionTitel}
                descreption={conditionTitel}
                image={null}

            />


            <React.Fragment class="">
              
                        <div class="col-lg-12 p-4 bg-white rounded shadow-sm">
                            <h4 class="mb-4 profile-title">Return and Refund Policy</h4>
                            <div id="terms_conditions">
                            <p>We want you to be completely satisfied with your purchase from Jeffry Med RX. If you're not satisfied for any reason, you may return your order within 10 days from the date of delivery for a refund or replacement.</p>

<h2>Returns</h2>
<p>To initiate a return, please contact our customer service at <a class="contact-link" href="mailto:contact@jeffrymedrx.com">contact@jeffrymedrx.com</a> within the 10-day period. Please include your order number and the reason for the return in your email. Once your return request is approved, we will provide you with instructions for returning the products.</p>

<h2>Refunds</h2>
<p>Upon receiving the returned products, we will inspect them to ensure they are in their original condition and packaging. Once the return is approved, we will process the refund to your original payment method. Please allow up to [10 business days] for the refund to appear in your account, depending on your payment provider.</p>

<h2>Replacement</h2>
<p>If you choose a replacement for the returned products, we will cover the shipping charges for the replacement.</p>

<h2>Contact Us</h2>
<p>If you have any questions or concerns about our Return and Refund Policy, please don't hesitate to <a class="contact-link" href="mailto:contact@jeffrymedrx.com">contact us</a>. We're here to help!</p>

                                </div>
                        </div>
                    

                
            </React.Fragment>

        </>
    )

}

export default Retun;