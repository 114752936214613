import React from 'react';

const OshanContainer = (props) => {

    return (
        <>
            <div class="wrapper">
                {props.children}
            </div>

        </>
    )

}

export default OshanContainer;