import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import ContextData from '../../context/MainContext';

const AccountContainer = (props) => {

    const { user } = useContext(ContextData);

    // if (!user.user_info) {
    //     return <Navigate to="/" />
    // }

    return (
        <>
            <div class="wrapper">
                <div class="dashboard-group">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="user-dt">
                                    <div class="user-img mb-3">
                                        <img src="images/avatar/img-5.jpg" alt="" />
                                    </div>
                                    {user && <p style={{ color: "#fff" }}>{user?.user_info?.mobile}<a href="#"></a></p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="container">
                        <div class="row">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AccountContainer;