import React from 'react';
import Category from './Category';
import OshanContainer from '../comman/OshanContainer';
import Promo from './Promo';
import TodayPicks from './TodayPicks';
import Banner from './Banner';
import SubCategorySliding from './SubCategorySliding';


import Seo from '../Seo'

const HomeComponent = (props) => {

    return (
        <>

            <Seo
                title={"Jeffry Med RX "}
                descreption={"Welcome to Jeffry Med RX, your trusted online pharmacy where your health and satisfaction are our top priorities."}
                image={null}

            />




            <OshanContainer >



                <SubCategorySliding />

                <Banner />

                <Category />



                <Promo />

                <TodayPicks />

            </OshanContainer>
        </>
    )

}

export default HomeComponent;