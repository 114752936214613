import React, { useContext, useEffect, useState } from 'react';
import MainData from '../../context/MainContext';
import AccountContainer from '../comman/AccountContainer';

import AccountNavigatinMenu from './AccountNavigatinMenu'

import Terms from './Terms'
import AboutUs from './AboutUs'
import PrivacyPolicy from './PrivacyPolicy'
import Retun from './Retun'
import TearmCondition from './TearmCondition'


import { useLocation } from 'react-router-dom'





const MainConditionComponent = (props) => {

    const data = useContext(MainData);
    const condition = data.condition[0];
    const Storefaq = data.Storefaq;

    // console.log('faq', faq)

    const location = useLocation();


    return (
        <>



            <AccountContainer>
                <AccountNavigatinMenu />

                <div class="col-lg-9 col-md-8">
                    <div class="dashboard-right">
                        <div class="row">
                            {condition &&
                                <>
                                    {location.pathname == '/about' ? <AboutUs conditionTitel="About" conditionData={condition.about} /> : null}
                                    {location.pathname == '/term-and-condition' ? <TearmCondition conditionTitel="Term & Condition" conditionData={condition.terms} /> : null}
                                    {location.pathname == '/privacy-and-policy' ? <PrivacyPolicy conditionTitel="Privacy & Policy" conditionData={condition.privacy} /> : null}
                                    {location.pathname == '/shipping-policy' ? <Terms conditionTitel="Shipping Policy" conditionData={condition.shiiping} /> : null}
                                    {location.pathname == '/return-and-refund-policy' ? <Retun conditionTitel="Return & Refund Policy" conditionData={condition.returns} /> : null}
                                    {location.pathname == '/faq' ? <Terms conditionTitel="FAQ" conditionData={Storefaq} /> : null}
                                </>}
                        </div>
                    </div>
                </div>

            </AccountContainer>





        </>
    )

}

export default MainConditionComponent;