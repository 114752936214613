import React from 'react';

import { useLocation } from 'react-router-dom'

import Seo from '../Seo'

const TearmCondition = ({ conditionTitel, conditionData }) => {
    const location = useLocation();

    return (
        <>

            <Seo
                title={conditionTitel}
                descreption={conditionTitel}
                image={null}

            />


            <React.Fragment class="">
              
                        <div class="col-lg-12 p-4 bg-white rounded shadow-sm">
                            <h4 class="mb-4 profile-title">Term and Condition</h4>
                            <div id="terms_conditions">
                            <p>Welcome to Jeffry Med RX! By accessing and using our website, you agree to comply with and be bound by the following terms and conditions. If you do not agree with these terms, please do not use our website.</p>

<h2>1. Use of Website</h2>
<p>Our website is intended for informational purposes and provides access to pharmaceutical products. You must use this website in accordance with all applicable laws and regulations.</p>

<h2>2. Medical Disclaimer</h2>
<p>Information provided on our website is for general informational purposes only and should not be considered medical advice. Consult with a qualified healthcare professional before making any decisions regarding your health or medication.</p>

<h2>3. Privacy</h2>
<p>Your privacy is important to us. Please review our <a href="https://jeffrymedrx.com/privacy-and-policy">Privacy Policy</a> to understand how we collect, use, and protect your personal information.</p>

<h2>4. Intellectual Property</h2>
<p>The content, trademarks, logos, and other intellectual property on this website are owned by Jeffry Med RX or its licensors. You may not use, reproduce, or distribute any content without our explicit written permission.</p>

<h2>5. Links to Third-Party Sites</h2>
<p>Our website may contain links to third-party websites for your convenience. We do not endorse or control these websites and are not responsible for their content or activities. Use third-party sites at your own risk.</p>

<h2>6. Changes to Terms</h2>
<p>We reserve the right to modify these terms and conditions at any time without notice. It is your responsibility to review this page periodically for any changes.</p>

<h2>7. Contact Us</h2>
<p>If you have any questions or concerns about these terms and conditions, please contact us at <a href="mailto:contact@jeffrymedrx.com">contact@jeffrymedrx.com</a>.</p>

<p>Last updated: [05/04/2023]</p>
                                </div>
                        </div>
                    

                
            </React.Fragment>

        </>
    )

}

export default TearmCondition;