import React, { useContext, useEffect } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import contextData from '../context/MainContext';
import HomeComponent from '../component/HomePage/HomeComponent';

import { Helmet } from "react-helmet";


const HomePage = () => {

  const data = useContext(contextData);

  return (
    <>
  

      <Header />

      <HomeComponent />

      <Footer />

    </>
  );
}

export default (HomePage);
