import React from 'react';

import { useLocation } from 'react-router-dom'

import Seo from '../Seo'

const AboutUs = ({ conditionTitel, conditionData }) => {
    const location = useLocation();

    return (
        <>

            <Seo
                title={conditionTitel}
                descreption={conditionTitel}
                image={null}

            />


            <React.Fragment class="">
              
                        <div class="col-lg-12 p-4 bg-white rounded shadow-sm">
                            <h4 class="mb-4 profile-title">Welcome to Jeffry Med RX - Your Trusted Online Pharmacy</h4>
                            <div id="terms_conditions">
                            <p>Welcome to Jeffry Med RX, your trusted online pharmacy where your health and satisfaction are our top priorities.</p>
        <p>At Jeffry Med RX, we believe in providing the best products and services to our valued customers. We understand the importance of quick and secure deliveries, which is why we've partnered with industry-leading courier services such as FedEx and USPS to ensure your orders arrive in a timely manner.</p>
        <p>Our commitment to customer satisfaction is reflected in our 10 Days Refund Policy. If you're not completely satisfied with your purchase, we'll make it right within 10 days of delivery.</p>
        <p>We are here for you around the clock with our 24*7 support system. Our dedicated team of professionals is ready to assist you with any questions or concerns you may have. Your health and well-being matter to us, and we're here to help.</p>
        <p>When it comes to payment security, we take it seriously. Our secure payment system ensures that your personal and financial information is protected, giving you peace of mind while making your purchases.</p>
        <p>Thank you for choosing Jeffry Med RX for your healthcare needs. We look forward to serving you and ensuring your experience with us is exceptional.</p>
                            </div>
                        </div>
                    

                
            </React.Fragment>

        </>
    )

}

export default AboutUs;